import {
    defineStore
} from "pinia";
import PointCampaignConsts from "../consts/PointCampaignConsts";

export const useStore = defineStore("main", {
    // state: {
    // },
    // mutations: {
    // },
    state: () => ({
        host: "https://drr-guide.com",
        api: "https://drr-guide.com",

        //ローカルかどうか
        isLocal: false,
        //ローカル時のPHPとのセッションID
        SessionId: "",
        //スマホかどうか
        isMobile: false,
        //商品編集などで使う商品情報
        select_item_id: 0, //一覧等から選択された商品ID（新規は０）
        select_item_category_id: 0, //一覧等から選択された商品カテゴリID
        //ユーザー編集等で使うユーザー情報
        select_user_id: 0, //一覧などで選択されたユーザーID（新規なら０）

        //キレイ処コンパネ内で使う奴
        KireiCtrlLogin: "", //ログイン済みかどうか ログイン＝”ON"
        KireiCtrlLoginShop: 0, //ログイン済の店舗 1:岐阜店 2:各務原店 3:関店 4:城東通店
        KireiCtrlLoginShopName: "", //ログイン済の店舗名
        KireiCtrlOrderID: 0, //キレイコンパネで選択した注文ID

        //リセラコンパネ内で使う奴
        RecellaCtrlLogin: false, //ログイン済みかどうか ログイン＝”ON"
        //
        //マイページ内で使うやつ
        //
        MyPageLogin: false, //0で未ログイン 1でログイン済み
        MyPageID: 0, //マイページにログインしているUSERID
        MyPageName: "", //マイページにログインしている名前
        MyPageOrderID: 0, //マイページで選択した注文ID
        MyPageUserData: "", //ランク等のユーザーデータ
        MyPageUserShopJ: false, //店舗ユーザーか？
        MyPageUserAquaJ: false, //アクアの判定
        MyPageUserAdsJ: false, //Adsの判定
        MyPageUserRecellaJ: false, //リセラが承認したか
        MyPageUserAdsMoveAgree: false, //ADS移動届に承認しているか
        MyPageUserAdsShopReg: false, //ADSをキレイ処店舗で登録しているか
        MyPageUserCustomerStatus: 9, //顧客の承認系状態 0:未処理 4:ADS移動届承認待ち 9:非承認 99:承認済

        //===============================================
        //リスト類
        //毎回読むのはコストが掛かるのでログイン時に読み込む
        //===============================================
        prefList: [],
        shopList: [],

        rankList: [{
                id: 0,
                name: "ノーマル",
            },
            {
                id: 1,
                name: "ブロンズ",
            },
            {
                id: 2,
                name: "シルバー",
            },
            {
                id: 3,
                name: "ゴールド",
            },
            {
                id: 4,
                name: "プラチナ",
            },
            {
                id: 5,
                name: "ダイアモンド",
            },
        ],

        // 全体キャンペーン
        wholePointCampaignData: null,
        wholeDiscountCampaignData: null,

        //
        //CSS
        //
        maxwidth: "768px",
    }),
    getters: {
        rootUrl(state) {
            return state.api;
        },
        getWholePointCampaignData(state) {
            return state.wholePointCampaignData;
        },
        getWholeDiscountCampaignData(state) {
            return state.wholeDiscountCampaignData;
        },
    },
    actions: {
        setHost(val) {
            this.host = val;
        },
        setApi(val) {
            this.api = val;
        },
        setIsLocal(val) {
            console.log("setIsLocal");
            console.log(val);
            this.isLocal = val;
        },
        setSessionId(val) {
            this.SessionId = val;
        },
        //選択されている商品ID
        setSelItemID(val) {
            this.select_item_id = val;
        },
        //選択されている商品カテゴリID
        setItemCate(val) {
            this.select_item_category_id = val;
        },
        //選択されているユーザーID
        setSelUserID(val) {
            this.select_user_id = val;
        },
        //モバイルかどうかを設定
        setIsMobile(val) {
            this.isMobile = val;
        },

        //=============
        //リスト類
        //=============
        //都道府県リスト
        setPrefList(val) {
            this.prefList = val;
        },
        setShopList(val) {
            this.shopList = val;
        },

        //
        //キレイ処管理画面用
        //

        //キレイ処のログイン状態変更
        setKireiCtrlLogin(val) {
            this.KireiCtrlLogin = Boolean(val);
        },
        //キレイ処のログイン店舗変更
        setKireiCtrlLoginShop(val) {
            this.KireiCtrlLoginShop = val;
        },
        //キレイ処のログイン店舗変更
        setKireiCtrlLoginShopName(val) {
            this.KireiCtrlLoginShopName = val;
        },
        //選択されている注文ID
        setKireiOrderID(val) {
            this.KireiCtrlOrderID = val;
        },

        //
        //リセラのログイン状態変更
        //
        setRecellaLogin(val) {
            this.RecellaCtrlLogin = Boolean(val);
        },

        //
        //マイページ関係
        //
        resetMyPageStoreData() {
            this.setMyPageLogin(false);
            this.setMyPageID(0);
            this.setMyPageName("");
            this.setMyPageOrderID(0);
            this.setMyPageUserData("");
        },
        //マイページのログイン状態変更
        setMyPageLogin(val) {
            this.MyPageLogin = val;
            console.log("Store::MyPageLogin:" + this.MyPageLogin);
        },
        //マイページのログインID変更
        setMyPageID(val) {
            this.MyPageID = val;
        },
        //マイページのログイン名変更
        setMyPageName(val) {
            this.MyPageName = val;
        },
        //ユーザーのランク等のデータ変更
        setMyPageUserData(val) {
            console.log("userDataSet=======");
            this.MyPageUserData = val;
        },
        //マイページの選択注文明細
        setMyPageOrderID(val) {
            this.MyPageOrderID = val;
        },
        //マイページのユーザー、キレイ処ユーザー判定
        setMyPageKireiUser(val) {
            this.MyPageUserShopJ = val;
        },
        //マイページのユーザー、Aqua判定
        setMyPageAqua(val) {
            this.MyPageUserAquaJ = val;
        },
        //マイページのユーザー、Ads判定
        setMyPageAds(val) {
            this.MyPageUserAdsJ = val;
        },
        //マイページのユーザー、Ads移動届提出同意
        setMyPageAdsMoveAgree(val) {
            this.MyPageUserAdsMoveAgree = val;
        },
        //マイページのユーザー、リセラが承認したか？
        setMyPageRecella(val) {
            this.MyPageUserRecellaJ = val;
        },
        //マイページのユーザー、リセラが承認したか？
        setMyPageAdsShopReg(val) {
            this.MyPageUserAdsShopReg = val;
        },
        //マイページのユーザー、customer_status
        setMyPageUserCustomerStatus(val) {
            this.MyPageUserCustomerStatus = val;
        },
        // 
        setWholeCampaignData() {
            axios
                .get(`${this.rootUrl}/api_drrGuide/campaign/get-whole-campaign-data`)
                .then(response => {
                    console.log(response);
                    if (response.data.status === 200) {
                        this.wholePointCampaignData = response.data.wholePointCampaignData;
                        this.wholeDiscountCampaignData = response.data.wholeDiscountCampaignData;
                    } else {
                        alert('error in setWholeCampaignData');
                    }
                    return;
                })
                .catch(error => {
                    console.log(error);
                });
        },
    },
    //データ保持の永続化を有効に
    persist: {
        enabled: true,
        strategies: [{
            key: "drr",
            storage: localStorage,
        }, ],
    },
});
