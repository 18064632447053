<script setup>
// imports
import { ref, watch, onMounted } from 'vue';
import { useStore } from '@/store/index.js';
import { useLogisticsStore } from '@/store/logistics.js';
import { useRouter } from 'vue-router'

// Consts
import GeneralConsts from "@/consts/GeneralConsts";
import LogisticsConsts from "@/consts/LogisticsConsts";

// store, router
const store = useStore();
const logisticsStore = useLogisticsStore();
const router = useRouter();

// props
const props = defineProps({
  fileTypeId: String
})

// 
const fileStatusList = logisticsStore.getFileStatusList;

// 
const loading = ref(false);
const pageSize = ref(10);
const pagerCount = ref(7);
const totalCount = ref(0);
const currentPage = ref(1);
const fileList = ref([]);
const checked = ref([]);

// 
const tableRowClassName = ({
  row,
  rowIndex,
}) => {
  // 
  let className = '';
  if (row.logisticsFileStatusId === LogisticsConsts.FILE_STATUS_ID_CONFIRMING) {
    className = 'confirming';
  } else if (row.logisticsFileStatusId === LogisticsConsts.FILE_STATUS_ID_COMPLETE) {
    className = 'complete';
  } else {
    // no action.
  }

  // 
  return className;
}

// 
watch(
  () => currentPage.value,
  (currentPage) => {
    getFileList(currentPage);
  }
);

// 
onMounted(() => {
  getFileList(1);
})

// 
const getFileList = (currentPage) => {
  // 
  loading.value = true;

  // 
  axios
    .get(`${store.rootUrl}/api_drrGuide/logistics/get-file-list?file_type_id=${props.fileTypeId}&current_page=${currentPage}&page_size=${pageSize.value}`)
    .then((response) => {
      console.log(response);

      // 
      if (response.data.status === 200) {
        totalCount.value = response.data.totalCount;
        fileList.value = response.data.list;
      } else if (response.data.status === 401) {
        router.push('/logistics/login');
      } else {
        alert(GeneralConsts.apiErrorMessage);
      }

      // 
      loading.value = false;
    })
    .catch(function (error) {
      console.log(error);
      loading.value = false;
    });
}
const handleCurrentChange = (val) => {
  console.log(`current page: ${val}`)
}
const download = (fileId) => {
  // 
  axios
    .get(`${store.rootUrl}/api_drrGuide/logistics/download-file?file_id=${fileId}`)
    .then((response) => {
      console.log(response);

      // 
      if (response.data.status === 200) {
        location.href = `${store.rootUrl}/api_drrGuide/file/download`;
      } else {
        alert(GeneralConsts.apiErrorMessage);
      }
    })
    .catch(function (error) {
      console.log(error);
    });
}
const updateStatus = (fileData) => {
  // 
  const params = new URLSearchParams();
  params.append('file_id', fileData.id);
  params.append('file_status_id', fileData.logisticsFileStatusId);
  axios
    .post(`${store.rootUrl}/api_drrGuide/logistics/update-file-status-id`, params)
    .then((response) => {
      console.log(response);
      if (response.data.status === 200) {
        // 
      } else {
        alert(GeneralConsts.apiErrorMessage);
      }
    })
    .catch(function (error) {
      console.log(error);
    });
}
const combineFilesAndDownload = () => {
  // 
  const checkedFileList = fileList.value.filter(fileData => fileData.checked === true);
  console.log(checkedFileList);

  // 
  if (checkedFileList.length >= 2) {
    // ok!
  } else {
    alert("ファイルを2つ以上選択してください。");
    return;
  }

  // 
  let message = ``;
  for (const checkedFileData of checkedFileList) {
    message += `・ID: ${checkedFileData.id}\n`;
  }
  message += `のファイルを1ファイルにまとめてダウンロードします。よろしいですか？`;
  if (confirm(message)) {
    // 
    const checkedFileIdList = checkedFileList.map(checkedFileData => checkedFileData.id);

    // 
    const params = new URLSearchParams();
    params.append('file_id_list', JSON.stringify(checkedFileIdList));
    axios
      .post(`${store.rootUrl}/api_drrGuide/logistics/combine-files-and-download`, params)
      .then((response) => {
        console.log(response);

        // 
        if (response.data.status === 200) {
          // 
          location.href = `${store.rootUrl}/api_drrGuide/file/download`;

          // 
          getFileList();
        } else {
          alert(GeneralConsts.apiErrorMessage);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  } else {
    // no action.
  }
}
</script>

<template>
  <el-container v-loading="loading" class="logistics-home-link-buttons">
    <el-row>
      <el-col>
        <div class="buttons-cover">
          <el-button @click="router.push(`/logistics/upload/${props.fileTypeId}`)" type="primary"
            size="large">ファイルをアップロードする</el-button>
          <el-button v-if="fileTypeId === LogisticsConsts.FILE_TYPE_ID_SHIPPING_ORDER"
            @click="combineFilesAndDownload()" type="success" size="large">１ファイルにまとめてダウンロード</el-button>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <div class="full-width-cover">
          <div class="pagination-cover">
            <el-pagination v-model:current-page="currentPage" :page-size="pageSize" :pager-count="pagerCount"
              :total="totalCount" layout="prev, pager, next" @current-change="handleCurrentChange" />
            <div>全{{ totalCount }}件</div>
          </div>
          <el-table :data="fileList" :row-class-name="tableRowClassName">
            <el-table-column width="50px">
              <template #header></template>
              <template #default="scope"><el-checkbox v-model="scope.row.checked" size="large" /></template>
            </el-table-column>
            <el-table-column width="120px">
              <template #header>ID</template>
              <template #default="scope">{{ scope.row.id }}</template>
            </el-table-column>
            <el-table-column width="200px">
              <template #header>アップロード日時</template>
              <template #default="scope">{{ scope.row.formattedCreatedAt }}</template>
            </el-table-column>
            <el-table-column>
              <template #header>アップロードした企業</template>
              <template #default="scope">
                <span v-if="logisticsStore.getCompanyDataFromCompanyList(scope.row.companyId) !== undefined">{{
                  logisticsStore.getCompanyDataFromCompanyList(scope.row.companyId).name }}</span>
                <span v-else>-</span>
              </template>
            </el-table-column>
            <el-table-column width="120px">
              <template #header>
                <div class="center">タイプ</div>
              </template>
              <template #default="scope">
                <div class="center">
                  <span v-if="scope.row.uploadType === '0'">自動</span>
                  <span v-else-if="scope.row.uploadType === '1'">手動</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column width="150px">
              <template #header>
                <div class="center">ダウンロード</div>
              </template>
              <template #default="scope">
                <div class="center">
                  <el-button @click="download(scope.row.id)" type="success">ダウンロード</el-button>
                </div>
              </template>
            </el-table-column>
            <el-table-column width="120px">
              <template #header>
                <div class="center">ステータス</div>
              </template>
              <template #default="scope">
                <div class="center">
                  <el-select v-model="scope.row.logisticsFileStatusId" @change="updateStatus(scope.row)"
                    placeholder="Select" size="large" style="width: 240px">
                    <el-option v-for="fileStatusData in fileStatusList" :key="fileStatusData.id"
                      :label="fileStatusData.name" :value="fileStatusData.id" />
                  </el-select>
                  <!-- <el-tag type="danger">未確認</el-tag> -->
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="pagination-cover">
            <el-pagination v-model:current-page="currentPage" :page-size="pageSize" :pager-count="pagerCount"
              :total="totalCount" layout="prev, pager, next" @current-change="handleCurrentChange" />
            <div>全{{ totalCount }}件</div>
          </div>
        </div>
      </el-col>
    </el-row>
  </el-container>
</template>

<style lang="scss" scoped>
.full-width-cover {

  // margin: 0 calc(50% - 49vw);
  // width: 98vw;
  :deep(.el-table) {
    .center {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
  .pagination-cover {
    margin: 0.3em 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.buttons-cover {
  display: flex;
  justify-content: space-between;
}

:deep(table) {
  tr {
    &.confirming {
      // background-color: var(--el-color-warning-light-5);
    }

    &.complete {
      background-color: #888;
    }
  }
}
</style>